/* .myTable {
    width: max-content !important;
    margin-bottom: 0 !important;
} */



.uploadFile {
    font-size: 16px;
    background: white;
    border-radius: 0.25rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.47);
    width: 100%;
    outline: none;
    margin: 10px 0;
}

.loadingSensors{
 color: green;
 font-size: large;
}

::-webkit-file-upload-button {
    color: white;
    background: #212529;
    padding: 10px 20px;
    border: none;
    border-radius: 0.25rem;
    box-shadow: 1px 0 1px #6b4559;
    outline: none;
    margin: 0 10px 0 0;
}

::-webkit-file-upload-button:hover {
    background: black;
}