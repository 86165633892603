#infoWindow{
    padding: 5px 15px;
}
.infoWindowLine{
    display:flex;
    justify-content: space-between;
}
 .signal-color-fec00f{
    color:#fec00f;
}
 .signal-color-c1b931{
    color:#c1b931;
}
 .signal-color-f6eb14{
    color:#f6eb14;
}
 .signal-color-ed2024{
    color:#ed2024;
}
 .signal-color-94c954{  
    color:#94c954;
}
 .signal-color-00b14f{ 
    color:#00b14f;
}
 
.sensorInfoLine{
    font-weight: bold;
}
.sensorInfoWindow{
    padding: 0 5px;
}
.sensorInfoBody{
    padding: 2px 2px;
}
.gm-ui-hover-effect {
    display: block !important;
    color :rgb(136, 135, 135)
}
/* .gm-style-iw .gm-style-iw-c{
    padding: 0px;
}
.gm-style-iw-d{
    padding: 0px;

} */